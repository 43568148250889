import axios from "../util/request";

// 登录地址
function user_login (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 商家信息
function business_info (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 区域列表
function area_list (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 桌位列表
function table_list (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 已预订列表 
function reserve_list (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 订单详情 
function order_detail (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 店员列表  指派经理
function manager_list (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 常用备注
function notes_list (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 录入、更改预订 立即预订
function book_now (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 设置订单状态
function order_status (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

// 发送短信
function sendSms (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

// 微信登录检测微信绑定的商家
function CheckSellerAcc (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

// 微信登录获取code
function wx (params = {}) {
    return axios.post('/wxapi',null, {
        params
    })
}

// 获取宴会类型
function GetClassify (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

//创建宴会意向
function CreateBanquet (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

//获取宴会员工
function GetStaff (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

//获取宴会详情
function GetBanquetById (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

//获取日期、时段下宴会列表
function GetBanquetByDate (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}

// PC根据手机号获取预订记录
function GetReservePcByPhone (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 根据商家ID获取商家信息
function GetSellerById (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家订座配置
function GetDzSellerConf (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 发送取消短信
function WxSendCancelSms (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家的版本信息
function GetSellerVersion (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 根据商家ID或者预订人手机号模糊搜索
function GetReserveUser (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 通过预订ID查收定金详情
function GetReserveDepositByRid (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 通过预订ID查核餐详情
function GetReserveRecheckByRid (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 创建收定金
function CreateReserveDeposit (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 删除定金
function DelReserveDepositByid (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 创建核餐
function CreateReserveRecheck (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取PC消息中心
function GetPcReserveMessage (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 保存PC消息
function SavePcReserveMessage (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 设置PC消息状态
function SetPcReserveMessageById (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 根据预定ID 修改当前预定状态
function SetReserveStatus (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 设置订座商家配置信息（录音配置）
function SetConfing (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 创建周到安排商家首页小程序URL
function CreateLiteIndexUrlLink (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 向指定手机号码发送挂机短信
function CloseMessage (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取用餐类型
function GetReserveTypeField (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取七牛上传token信息
function GetUploadToken (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 取消订单设置定金走向
function SetReserveDeposit (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取预订操作记录
function GetReserveActionLog (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家短信发送记录
function GetReserveSmsLog (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取账号服务类型
function GetSellerUserAccountType (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 设置订单服务经理
function SetServeUidByRid (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家桌位列表及对应预订数据
function GetTableListOrReserve (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家桌位是否存在预订或宴会订单
function GetTableItExistOrder (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取商家预订列表并对区域桌位排序
function getReserveListAlsoOrder (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 预订详情所需数据集合
function GetReserveDetailDataSet (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 获取日期对应的时段
function GetMealSectionByDay (params = {}) {
    return axios.post('/api/',null, {
        params
    })
}
// 导出
export {
    user_login,
    order_detail,
    table_list,
    area_list,
    reserve_list,
    business_info,
    manager_list,
    notes_list,
    book_now,
    order_status,
    sendSms,
    CheckSellerAcc,
    wx,
    GetClassify,
    CreateBanquet,
    GetStaff,
    GetBanquetById,
    GetBanquetByDate,
    GetReservePcByPhone,
    GetSellerById,
    GetDzSellerConf,
    WxSendCancelSms,
    GetSellerVersion,
    GetReserveUser,
    GetReserveDepositByRid,
    GetReserveRecheckByRid,
    CreateReserveDeposit,
    DelReserveDepositByid,
    CreateReserveRecheck,
    GetPcReserveMessage,
    SavePcReserveMessage,
    SetPcReserveMessageById,
    SetReserveStatus,
    SetConfing,
    CreateLiteIndexUrlLink,
    CloseMessage,
    GetReserveTypeField,
    GetUploadToken,
    SetReserveDeposit,
    GetReserveActionLog,
    GetReserveSmsLog,
    GetSellerUserAccountType,
    SetServeUidByRid,
    GetTableListOrReserve,
    GetTableItExistOrder,
    getReserveListAlsoOrder,
    GetReserveDetailDataSet,
    GetMealSectionByDay,
}